// FORMRANGE
(() => {
  let rangeInputs =
    document.querySelectorAll('.tc_form__range:not(.js-processed)') || [];

  const setCssValue = (elm, value, max) => {
    const progress = Math.round(((value * 100) / max) * 10) / 10;
    elm.style.setProperty('--value', progress + '%');
  };

  rangeInputs.forEach((range) => {
    // set value on user input
    range.addEventListener('input', () => {
      setCssValue(range, range.value, range.max);
    });

    // set value on js changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        setCssValue(
          mutation.target,
          mutation.target.value,
          mutation.target.max,
        );
      });
    });

    observer.observe(range, {
      attributes: true,
      attributeFilter: ['value', 'max'],
    });

    // set value on initial load
    setCssValue(range, range.value, range.max);

    range.classList.add('js-processed');
  });
})();
